import User from "@/service/user.api";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  signUp: (_store, userData) => {
    return User.signUp(userData)
      .then(({ data }) => data)
      .catch((err) => {
        console.error("err store", err);
        return Promise.reject(err);
      });
  },
  getUser: (_store) => {
    return User.getUser()
      .then(({ data }) => data)
      .catch((err) => {
        console.error("err store", err);
        return Promise.reject(err);
      });
  },
  getUsers: (_store) => {
    return User.getUsers()
      .then(({ data }) => data)
      .catch((err) => {
        console.error("err store", err);
        return Promise.reject(err);
      });
  },
  getUserById: (_store, id) => {
    return User.getUserById(id)
      .then(({ data }) => data)
      .catch((err) => {
        console.error("err store", err);
        return Promise.reject(err);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
