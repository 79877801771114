import httpClient from "@/service/httpClient";

const baseEndpoint = "/api/v1";

const getForms = () => httpClient.get(`${baseEndpoint}/formdraft/false`);

const drafts = () => httpClient.get(`${baseEndpoint}/formdraft/true`);

const getFormByID = (id) => httpClient.get(`${baseEndpoint}/form/${id}`);

const getFormsByPlate = (plate) =>
  httpClient.get(`${baseEndpoint}/formplate/${plate}`);

const postForm = (form) => {
  return httpClient.post(`${baseEndpoint}/form`, form);
};

const putForm = (form) => {
  return httpClient.put(`${baseEndpoint}/form`, form);
};

const deleteForm = (id) => {
  return httpClient.delete(`${baseEndpoint}/form/${id}`);
};

export default {
  getForms,
  getFormsByPlate,
  getFormByID,
  postForm,
  drafts,
  putForm,
  deleteForm,
};
