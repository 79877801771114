function inputMapper(template, key = null) {
  switch (template.type) {
    case "stepper":
      return stepperMapper(template, key);
    case "simple":
      return simpleMapper(template, key);
    case "vertical-stepper":
      return stepperMapper(template, key);
    default:
      return simpleMapper(template, key);
  }
}

function stepperMapper(template, key) {
  let inputMap = new Map();
  template.items.forEach((step) => {
    step.form.items.forEach((item) => {
      inputSetter(item, inputMap, key);
    });
  });
  return inputMap;
}

function inputSetter(item, inputMap, key) {
  if (key) {
    inputMap.set(item.name, item[key]);
  } else {
    inputMap.set(item.name, item);
  }

  if (item.type == "group") {
    item.items.forEach((item) => {
      inputSetter(item, inputMap, key);
    });
  }
}

function simpleMapper(template) {}

export default inputMapper;
