import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth.store";
import forms from "@/store/modules/forms.store";
import templates from "@/store/modules/template.store";
import fleetsafe from "@/store/modules/fleetsafe.store";
import navbar from "@/store/modules/navbar.store";
import user from "@/store/modules/user.store";
import pwa from "@/store/modules/pwa.store";
import org from "@/store/modules/organizations.store";
import image from "@/store/modules/image.store";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isFreshReload: true,
    isEditing: false,
    swipe: false,
  },
  getters: {
    getIsFreshReload: (state) => {
      return state.isFreshReload;
    },
    getIsEditing: (state) => {
      return state.isEditing;
    },
    getSwipe: (state) => {
      return state.swipe;
    },
  },
  mutations: {
    setIsFreshReload: (state, payload) => {
      state.isFreshReload = payload;
    },
    setIsEditing: (state, payload) => {
      state.isEditing = payload;
    },
    setSwipe: (state, payload) => {
      if (payload)
        setTimeout(() => {
          state.swipe = false;
        }, 500);
      state.swipe = payload;
    },
  },
  actions: {
    resetAllStates: ({ dispatch, commit }) => {
      dispatch("auth/resetAuthStore");
      dispatch("forms/resetFormsStore");
      dispatch("templates/resetTempStore");
      dispatch("image/resetImageStore");
    },
  },
  modules: {
    auth,
    forms,
    templates,
    fleetsafe,
    navbar,
    user,
    pwa,
    org,
    image,
  },
});
