import httpClient from "@/service/httpClient";

const baseEndpoint = "/api/v1";

const getOrganizations = () => httpClient.get(`${baseEndpoint}/organizations`);
const getCurrentOrganization = () =>
  httpClient.get(`${baseEndpoint}/current-organization`);
const getOrganizationByID = (id) =>
  httpClient.get(`${baseEndpoint}/organization/${id}`);
const getOrganizationByName = (name) => {
  return httpClient.get(`${baseEndpoint}/organization/name/${name}`);
};

const updateOrganizationPermission = (name, permissions) => {
  return httpClient.put(
    `${baseEndpoint}/organization/${name}/permission`,
    permissions
  );
};

export default {
  getOrganizations,
  getCurrentOrganization,
  getOrganizationByID,
  getOrganizationByName,
  updateOrganizationPermission,
};
