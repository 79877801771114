import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

import freshLoad from "@/router/freshLoad";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/MainMenuView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "FleetSafe Check",
      section: "FleetSafe Check",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/TheTestView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "FleetSafe Check",
      section: "TEST | FleetSafe Check",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/config",
    name: "config",
    component: () => import("@/views/ConfigView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "FleetSafe Check",
      section: "Config | FleetSafe Check",
      requiredPermissions: [],
      needOnline: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      requiresAuth: false,
      showNav: false,
      title: "Login | FleetSafe Check",
      section: "FleetSafe Check",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("@/views/SignUpView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "Signup | FleetSafe Check",
      section: "FleetSafe Check",
      requiredPermissions: ["tempform:create"],
      needOnline: true,
    },
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("@/views/MainMenuView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "FleetSafe Check | FleetSafe Check",
      section: "Menu",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/forms",
    name: "forms",
    component: () => import("@/views/TheFormView.vue"),
    meta: {
      requiresAuth: true,
      showNav: true,
      title: "Forms | FleetSafe Check",
      section: "Formularios | FleetSafe Check",
      requiredPermissions: [],
      needOnline: false,
    },
    children: [
      {
        name: "form-selection",
        path: "selection",
        component: () => import("@/components/FormCardSelection.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "Forms | FleetSafe Check",
          section: "Formularios | FleetSafe Check",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
      },
      {
        name: "table",
        path: "table",
        component: () => import("@/views/FormDashboard.vue"),
        meta: {
          requiresAuth: true,
          showNav: true,
          title: "Forms | FleetSafe Check",
          section: "Formularios | FleetSafe Check",
          requiredPermissions: ["form:read"],
          needOnline: true,
        },
        children: [
          {
            name: "table-view",
            path: "view",
            component: () =>
              import("@/components/formsviewers/FormsDataTable.vue"),
            meta: {
              requiresAuth: true,
              showNav: false,
              title: "Forms | FleetSafe Check",
              section: "Formularios | FleetSafe Check",
              requiredPermissions: ["form:read"],
              needOnline: true,
            },
          },
          {
            name: "form-view",
            path: "form/:id",
            component: () =>
              import("@/components/formsviewers/TheFormViewer.vue"),
            meta: {
              requiresAuth: true,
              showNav: true,
              title: "Forms | FleetSafe Check",
              section: "Formulario",
              requiredPermissions: ["form:read"],
              needOnline: true,
            },
          },
        ],
      },
      {
        name: "checklist",
        path: "checklist/:id",
        component: () => import("@/views/step/TheNewStepManager.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "Check | FleetSafe Check",
          section: "Checklist",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
        children: [],
      },
      {
        path: "status",
        name: "form-status",
        component: () => import("@/views/FormStatusView.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "FleetSafe Check",
          section: "Finalizado",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
      },
      {
        name: "resume",
        path: "resume",
        component: () => import("@/components/TheResumeChecklist.vue"),
        meta: {
          requiresAuth: true,
          showNav: true,
          title: "Resume | FleetSafe Check",
          section: "Resumen",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
      },
    ],
  },
  {
    path: "/drafts",
    name: "drafts",
    component: () => import("@/views/drafts/TheDraftView.vue"),
    meta: {
      requiresAuth: true,
      showNav: false,
      title: "Borradores | FleetSafe Check",
      section: "Borradores",
      requiredPermissions: [],
      needOnline: false,
    },
    children: [
      {
        path: "selection",
        name: "draft-selection",
        component: () => import("@/components/FormCardSelection.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "Borradores | FleetSafe Check",
          section: "Borradores",
          requiredPermissions: [],
          needOnline: false,
        },
      },
      {
        name: "draft-checklist",
        path: "checklist/:id",
        component: () => import("@/views/step/TheNewStepManager.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "Check | FleetSafe Check",
          section: "Checklist Borrador",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
        children: [],
      },
      {
        path: "status",
        name: "draft-status",
        component: () => import("@/views/FormStatusView.vue"),
        meta: {
          requiresAuth: true,
          showNav: false,
          title: "FleetSafe Check",
          section: "Finalizado",
          requiredPermissions: ["form:create"],
          needOnline: false,
        },
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/errors/TheNotFoundView.vue"),
    meta: {
      requiresAuth: false,
      showNav: false,
      title: "FleetSafe Check",
      section: "404",
      requiredPermissions: [],
      needOnline: false,
    },
  },
  {
    path: "/offline",
    name: "offline",
    component: () => import("@/views/errors/TheOnlineNeeded.vue"),
    meta: {
      requiresAuth: false,
      showNav: false,
      title: "FleetSafe Check",
      section: "Offline",
      requiredPermissions: [],
      needOnline: false,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ?? "FleetSafe Check";
  // Prevenir redudant route
  if (from.name === to.name) return false;
  //Caso sin tokens en local
  const isLocalEmpty =
    localStorage.getItem("token") === null ||
    localStorage.getItem("refresh_token") === null;
  const requiresAuth = to.meta.requiresAuth;

  if (isLocalEmpty && requiresAuth) {
    setTimeout(() => {
      store.dispatch("resetAllStates");
    }, 500);
    return { name: "login" };
  }
  // va a ruta pero no está autenticado
  const isAuthenticated = store.getters["auth/getIsAuthenticated"];
  const isFreshReload = store.getters["getIsFreshReload"];

  if (requiresAuth && !isAuthenticated) {
    if (isFreshReload) {
      freshLoad(to, from, next);
    } else {
      store.dispatch("auth/signOut");
      return { name: "login" };
    }
  }
  const userPermissions = store.getters["auth/userPermissions"] ?? [];
  const routePermissions = to.meta.requiredPermissions ?? [];

  //check if all routePermissions are in userPermissions
  const hasPermissions = routePermissions.every((permission) =>
    userPermissions.includes(permission)
  );
  if (!hasPermissions) {
    return { name: "404" };
  }
  next();
});

router.beforeResolve((to, from, next) => {
  store.getters["pwa/isOffline"] && to.meta.needOnline
    ? next({ name: "offline" })
    : next();
});

export default router;
