var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{directives:[{name:"touch",rawName:"v-touch",value:({
  left: () => {
    _vm.drawer = false;
  },
}),expression:"{\n  left: () => {\n    drawer = false;\n  },\n}"}]},[(_vm.isAuthenticated)?_c('the-app-bar'):_vm._e(),_c('v-main',{directives:[{name:"touch",rawName:"v-touch",value:({
    right: () => _vm.swipe('right'),
    left: () => _vm.swipe('left'),
    up: () => _vm.swipe('up'),
    down: () => _vm.swipe('down'),
  }),expression:"{\n    right: () => swipe('right'),\n    left: () => swipe('left'),\n    up: () => swipe('up'),\n    down: () => swipe('down'),\n  }"}]},[(_vm.showMain)?_c('router-view',{scopedSlots:_vm._u([{key:"default",fn:function({ Component }){return [_c('transition',{attrs:{"mode":"out-in"}},[_c(Component,{tag:"component"})],1)]}}],null,false,1788529322)}):_c('loader-icon')],1),_c('the-form-appbar'),_c('v-footer',{attrs:{"padless":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" All Rights Reserved — "),_c('strong',[_vm._v("FleetSafe")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }