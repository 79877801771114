import httpClient from "@/service/httpClient";

const baseEndpoint = "/api/v1";

const uploadImage = (image) => {
  return httpClient.post(`${baseEndpoint}/upload`, image);
};

const getImageURL = (id) => {
  return httpClient.get(`${baseEndpoint}/image/${id}`);
};

const getImageById = (id) => {
  return httpClient
    .get(`${baseEndpoint}/file-manager/image/${id}`, {
      responseType: "blob",
    })
    .then((response) => {
      return URL.createObjectURL(response.data);
    });
};

export default {
  uploadImage,
  getImageURL,
  getImageById,
};
