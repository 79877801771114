import axios from "axios";
import jwt_decode from "jwt-decode";

import Auth from "@/service/auth.api";
import router from "../../router";

import { setHttpClientAuth } from "@/service/httpClient";

const state = {
  token: null,
  refresh_token: null,
  isAuthenticated: false,
  tokenInfo: null,
  userInfo: null,
};

const getters = {
  Auth: (state) => state.token,
  refreshToken: (state) => state.refresh_token,
  getIsAuthenticated: (state) => state.isAuthenticated,
  company: (state) => state.tokenInfo.company,
  userId: (state) => state.tokenInfo.id,
  userInfo: (state) => state.userInfo,
  expiration: (state) => state.tokenInfo.expires ?? null,
  fullName: (state) => {
    return `${state.userInfo.name ?? ""} ${state.userInfo.last_name ?? ""}`;
  },
  userPermissions: (state) => {
    const tokenInfo = state.tokenInfo ?? [];
    return (
      Object.keys(tokenInfo).filter((key) => {
        return key.includes(":") && tokenInfo[key] === true;
      }) ?? []
    );
  },
};

const mutations = {
  setToken: (state, payload) => {
    if (payload) {
      state.tokenInfo = jwt_decode(payload);
      localStorage.setItem("token", payload);
      setHttpClientAuth(payload);
      Auth.getUser(state.tokenInfo.id)
        .then(({ data }) => {
          state.userInfo = data.user;
          return data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    state.token = payload;
  },
  setRefreshToken: (state, payload) => {
    localStorage.setItem("refresh_token", payload);
    state.refresh_token = payload;
  },
  setIsAuthenticated: (state, payload) => {
    state.isAuthenticated = payload;
  },
  setUserInfo: (state, payload) => {
    state.userInfo = payload;
  },
  setTokenInfo: (state, payload) => {
    state.tokenInfo = payload;
  },
};

const actions = {
  signIn: ({ dispatch, commit }, { email, password }) => {
    return Auth.signIn(email, password)
      .then(({ data }) => {
        if (data.error) {
          throw new Error(data.error);
        }
        const TOKEN = data.tokens.access;
        const REFRESH_TOKEN = data.tokens.refresh;
        commit("setToken", TOKEN);
        commit("setRefreshToken", REFRESH_TOKEN);
        commit("setIsAuthenticated", true);
      })
      .catch((err) => {
        console.log("signIn error", err.response);
        return Promise.reject(err);
      });
  },
  getUser: ({ commit }) =>
    Auth.getUser()
      .then(({ data }) => {
        commit("setUserInfo", data.user);
        return data;
      })
      .catch((err) => console.log(err.response)),
  getUsers: () =>
    Auth.getUsers()
      .then(({ data }) => data)
      .catch((err) => console.log(err.response)),
  renewToken: (
    { dispatch, commit },
    payload = localStorage.getItem("refresh_token")
  ) => {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      if (token === null) reject(new Error("token is null"));
      Auth.renewToken(payload)
        .then(({ data }) => {
          commit("setToken", data.tokens.access);
          commit("setRefreshToken", data.tokens.refresh);
          commit("setIsAuthenticated", true);
          resolve(data.tokens.access);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  loginOnReload: ({ dispatch, commit, getters }) => {
    commit("setToken", localStorage.getItem("token"));
    commit("setRefreshToken", localStorage.getItem("refresh_token"));
    commit("setIsAuthenticated", true);
    commit("setIsFreshReload", false, { root: true });
  },
  signOut: ({ dispatch, commit }) => {
    console.log("sign out");
    return Auth.signOut()
      .then(() => {
        console.log("signout success");
        //limpiar local y store
        dispatch("resetAllStates", null, { root: true });
        localStorage.clear();
      })
      .catch((err) => {
        return err.response;
      });
  },

  getToken: ({ commit }) => {
    const token = localStorage.getItem("token"); //null if token doesn't exists
    commit("setToken", token);
    return token;
  },
  resetAuthStore: ({ commit }) => {
    commit("setToken", null);
    commit("setRefreshToken", null);
    commit("setIsAuthenticated", false);
    commit("setTokenInfo", null);
    commit("setUserInfo", null);
  },
  updateUserAndCompanyInfo: ({ commit, dispatch }) => {
    //actualizar info del usuario
    dispatch("getUser");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
