import httpClient from "@/service/httpClient";

const baseEndpoint = "/api/v1";

const getTempForms = () => httpClient.get(`${baseEndpoint}/tempforms`);

const getFullTempForms = () => httpClient.get(`${baseEndpoint}/fulltempforms`);

const getTempFormByID = (id) =>
  httpClient.get(`${baseEndpoint}/tempform/${id}`);

export default {
  getTempForms,
  getFullTempForms,
  getTempFormByID,
};
