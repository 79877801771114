import Forms from "@/service/forms.api";
import inputMapper from "@/utils/inputMapper";

import {
  IMAGE_DEFAULT_LOADING,
  IMAGE_DEFAULT_ERROR,
} from "@/utils/defaultValues";

function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

const state = {
  lastForm: null,
  currentForm: null,
  compactForm: null,
  headerForm: null,
  currentStep: null,
  canStepAdvance: true,
  canShowResume: false,
  canSendForm: false,
  drafts: null,
};

const mutations = {
  setLastForm(state, payload) {
    state.lastForm = payload;
  },
  setCurrentForm(state, payload) {
    state.currentForm = payload;
    if (payload) {
      state.compactForm = Object.fromEntries(inputMapper(payload, "value"));
    }
  },
  setCompactForm(state, payload) {
    state.compactForm = payload;
  },
  setCurrentStep(state, payload) {
    state.currentStep = payload;
  },
  setCanStepAdvance(state, payload) {
    state.canStepAdvance = payload;
  },
  setCanShowResume(state, payload) {
    state.canShowResume = payload;
  },
  setCanSendForm(state, payload) {
    state.canSendForm = payload;
  },
  setHeaderForm(state, payload) {
    state.headerForm = payload;
  },
  setDrafts(state, payload) {
    state.drafts = payload;
  },
};

const getters = {
  lastForm: (state) => state.lastForm,
  currentForm: (state) => state.currentForm,
  compactForm: (state) => state.compactForm,
  currentStep: (state) => state.currentStep,
  canAdvance: (state) => state.canStepAdvance,
  canShowResume: (state) => state.canShowResume,
  canSendForm: (state) => state.canSendForm,
  formHeader: (state) => state.headerForm,
  drafts: (state) => state.drafts,
};

const actions = {
  getForms: () =>
    Forms.getForms()
      .then(({ data }) => data)
      .catch((err) => console.error(err.respose)),
  getFormsByPlate: (_store, plate) =>
    Forms.getFormsByPlate(plate)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response)),
  drafts: (_store) =>
    Forms.drafts()
      .then(({ data }) => {
        _store.commit("setDrafts", data.forms);
        return data;
      })
      .catch((err) => console.error(err.response)),
  getFormByID: ({ dispatch, rootGetters }, id) =>
    Forms.getFormByID(id)
      .then(({ data }) => {
        //formulario basado en un template
        let form = deepClone(data.form);
        const formValues = form.form_attrs[0] ?? [];
        const formValuesKeys = Object.keys(formValues);
        //template utilizado
        return rootGetters["templates/getTemplateById"](form.bassed_on)
          .then((template) => {
            let template_to_fill = deepClone(template);
            const templateMap = inputMapper(template_to_fill.form_attrs[0]);
            //rellenar template con valores del formulario
            formValuesKeys.forEach((key) => {
              if (!templateMap.has(key)) return;
              templateMap.get(key).value = formValues[key];
            });
            form.form_attrs[0] = template_to_fill.form_attrs[0];

            return { form, templateMap };
          })
          .catch((err) => {
            console.error("get form id::get template by id catch error: ", err);
            return Promise.reject(err);
          });
      })
      .catch((err) => console.error("error getformid", err.response)),
  getFormByID_WithImages: ({ dispatch, rootGetters }, id) =>
    Forms.getFormByID(id)
      .then(({ data }) => {
        //formulario basado en un template
        let form = deepClone(data.form);
        const formValues = form.form_attrs[0] ?? [];
        const formValuesKeys = Object.keys(formValues);
        //template utilizado
        return rootGetters["templates/getTemplateById"](form.bassed_on).then(
          (template) => {
            let template_to_fill = deepClone(template);
            const templateMap = inputMapper(template_to_fill.form_attrs[0]);
            //rellenar template con valores del formulario
            formValuesKeys.forEach((key) => {
              //caso nulo
              if (!templateMap.has(key)) return;
              //caso image-input
              if (templateMap.get(key).type == "image-input") {
                //caso no viene imagen en el formulario
                if (!formValues[key]) {
                  templateMap.get(key).value = null;
                  return;
                }
                //imagen por defecto mientras se obtiene la imagen
                templateMap.get(key).value = IMAGE_DEFAULT_LOADING;
                dispatch("image/getImageById", formValues[key], {
                  root: true,
                }).then((res) => {
                  //se reemplaza la imagen por defecto por la imagen obtenida
                  templateMap.get(key).value = res;
                });
                return;
              }
              //caso image-input-multiple
              else if (templateMap.get(key).type == "image-input-multiple") {
                //Si no existen imagenes guardadas por el usuario
                if (!formValues[key]) {
                  templateMap.get(key).value = [null];
                  return;
                }
                //imagenes por defecto mientras se obtienen las imagenes
                templateMap.get(key).value = Array(
                  formValues[key].length ?? 1
                ).fill(IMAGE_DEFAULT_LOADING);
                //obtener imagenes
                formValues[key].forEach((imageUUID, index) => {
                  dispatch("image/getImageById", imageUUID, {
                    root: true,
                  })
                    .then((res) => {
                      //se reemplaza la imagen por defecto por la imagen obtenida
                      templateMap.get(key).value[index] = res;
                    })
                    .catch((err) => {
                      templateMap.get(key).value[index] = IMAGE_DEFAULT_ERROR;
                    });
                });
                return;
              }
              //en cualquier otro input
              templateMap.get(key).value = formValues[key];
            });

            form.form_attrs[0] = template_to_fill.form_attrs[0];

            return { form, templateMap };
          }
        );
      })
      .catch((err) => console.error("error getformid", err.response)),
  postForm: (_store, form) =>
    Forms.postForm(form)
      .then((res) => {
        // console.log("Store:Post Form ok:", res);
        return res;
      })
      .catch((err) => {
        console.error("Store:Post Form err:", err);
        Promise.resolve({});
      }),
  putForm: (_store, form) =>
    Forms.putForm(form)
      .then((res) => {
        // console.log("Store:Put Form ok:", res);
        return res;
      })
      .catch((err) => {
        console.error("Store:Put Form err:", err);
        Promise.resolve({});
      }),
  deleteForm: (_store, id) =>
    Forms.deleteForm(id)
      .then((res) => {
        // console.log("Store:Delete Form ok:", res);
        return res;
      })
      .catch((err) => {
        console.error("Store:Delete Form err:", err);
        Promise.resolve({});
      }),

  advanceCurrentStep: (_store, payload) => {
    _store.commit("setCurrentStep", _store.getters["currentStep"] + 1);
  },
  resetFormsStore: ({ commit }) => {
    commit("setLastForm", null);
    commit("setCurrentForm", null);
    commit("setCompactForm", null);
    commit("setHeaderForm", null);
    commit("setCurrentStep", null);
    commit("setCanStepAdvance", true);
    commit("setCanShowResume", false);
    commit("setCanSendForm", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
