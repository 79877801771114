import axios from "axios";
import store from "../store";
import router from "../router";

const BASE_URL = process.env.VUE_APP_BASE_URL;

const URL_404_exception = "/renew";

const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export const setHttpClientAuth = (token) => {
  //!datos del jwt al store
  httpClient.defaults.headers["Authorization"] = `Bearer ${token}`;
};

httpClient.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => err
);

httpClient.interceptors.response.use(null, (error) => {
  let response = error.response;
  const isTokenExpired = response.data.msg?.includes("expiration") ?? "no-msg";
  switch (response.status) {
    case 401:
      if (isTokenExpired) {
        return store.dispatch("auth/renewToken").then((newToken) => {
          setHttpClientAuth(newToken);
          response.config.headers.Authorization = `Bearer ${newToken}`;
          return axios.request(response.config);
        });
      } else {
        store.commit("auth/setIsAuthenticated", false);
      }
      break;
    case 404:
      const isException =
        response.request.responseURL.includes(URL_404_exception);
      if (isException) {
        store.dispatch("auth/signOut");
        router.push({ name: "login" });
      } else {
        router.push({ name: "404" });
      }
      break;
    default:
      // console.error("error interceptor", error.response);
      return Promise.reject(response);
  }
});

export default httpClient;
