const Essentialactions = [
  "templates/getFullTempForms",
  "fleetsafe/getUnits",
  "forms/drafts",
];

const EssentialRoutes = [
  "api/v1/fulltempforms",
  "api/v1/fleetsafe/units",
  "api/v1/formdraft/true",
];

export default Essentialactions;

export { Essentialactions, EssentialRoutes };
