import Fleetsafe from "@/service/fleetsafe.api";

const state = {
  units: [],
};
const mutations = {
  setUnits(state, payload) {
    state.units = payload;
  },
};
const getters = {};
const actions = {
  getUnits: ({ commit }) => {
    return Fleetsafe.getUnits()
      .then(({ data }) => {
        // console.log("getUnits", data);
        commit("setUnits", data.units.items ?? []);
        return data;
      })
      .catch((err) => console.error(err.response));
  },
  getUnit: (_store, id) => {
    return Fleetsafe.getUnit(id)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
  updateOdometer: (_store, { unitId, value }) => {
    return Fleetsafe.postOdometer(unitId, value)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
