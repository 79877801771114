import Templates from "@/service/template.api";

const state = {
  templates: null,
  currentTemplate: null,
};

const mutations = {
  setTemplates(state, templates) {
    state.templates = templates;
  },
  setCurrentTemplate(state, template) {
    state.currentTemplate = template;
  },
};

const getters = {
  getTemplates: (state) => state.templates,
  getTemplateById: (state) => (id) => {
    const foundTemplate = state.templates.find(
      (template) => template.id === id
    );

    if (!foundTemplate) return Promise.reject("Template not found");
    return Promise.resolve(foundTemplate);
  },
  getCurrentTemplate: (state) => state.currentTemplate,
};

const actions = {
  getTempForms: () =>
    Templates.getTempForms()
      .then(({ data }) => data)
      .catch((err) => console.error(err.response)),
  getFullTempForms: (_store) =>
    Templates.getFullTempForms()
      .then(({ data }) => {
        _store.commit("setTemplates", data.forms);
        return data.forms;
      })
      .catch((err) => console.error(err)),

  getTempFormByID: (_store, id) =>
    Templates.getTempFormByID(id)
      .then(({ data }) => data)
      .catch((err) => console.error(err.response)),
  resetTempStore: ({ commit }) => {
    commit("setTemplates", null);
    commit("setCurrentTemplate", null);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
